/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import {
  ParseContent,
  ContainerLeft,
  ImageBackground,
  Image,
} from 'components/shared'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import Back1 from 'img/journey.png'
import BackImg from 'img/back-logo.png'

const Content = styled(ParseContent)`
  & td {
    vertical-align: unset;
  }

  & tr {
    height: auto !important;

    td {
      height: auto !important;
    }

    @media screen and (max-width: 991px) {
      display: flex;
      flex-wrap: wrap;

      td {
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }
`

const DotsContent = styled(ParseContent)`
  h3 {
    padding-top: 10px;
    
    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.l} !important;
      line-height: 30px;
    }

    @media screen and (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.l} !important;
      line-height: 32px;
    }
  }

  img {
        width: 100%;
        max-width:400px;
        height: auto;
      }
`

const ContentWrapper = styled.div`
  position: relative;
  
  @media (min-width: 992px) {
    top: -100px;
  }
`

const CustomerJourneyFooter = styled.div`
  width: 600px;
  height: 100px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: ${(props) => props.theme.color.face.secondary};
  position: relative;
  border-radius: 50px;
  margin: 0 auto;

  h4 {
    font-size: ${(props) => props.theme.font.size.l};
    line-height: ${(props) => props.theme.font.size.l};
    color: ${(props) => props.theme.color.text.light};
    margin-bottom: 0;
  }

  button {
    font-size: ${(props) => props.theme.font.size.m};
    line-height: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.color.text.main};
    border: 2px solid ${(props) => props.theme.color.text.main};
    height: 50px;
    border-radius: 25px;
    padding-left: 40px;
    padding-right: 40px;
    transition: all 300ms ease;
    &:hover {
      background: ${(props) => props.theme.color.text.main};
      color: ${(props) => props.theme.color.text.light};
    }
  }

  position: relative;

  @media screen and (max-width: 1024px) {
    width: 600px;
  }

  @media screen and (max-width: 768px) {
    width: 400px;
    height: 120px;

    & h4 {
      text-align: center;
      width: 100%;
    }

    & a {
      margin: .5rem auto 0;
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => (
  <Layout activeMenu="Werkwijze">
    <SEO yoast={yoast} path={path} />

    <Hero fields={acf.banner} dots={acf.story.dots} />

    <UBXImage>
      <Image src={BackImg} style={{ width: '100%' }} />
    </UBXImage>
    <ContentWrapper className="container">
      <Content content={acf.story.description} />
    </ContentWrapper>

    <div className="container">
      <CustomerJourneyFooter className="d-flex flex-wrap ml-auto justify-content-between mt-1 mt-lg-n3 align-items-center">
        <h4 className="my-auto">The time is now!</h4>
        <a className="my-auto" href="javascript:void(Tawk_API.toggle())">
          <button type="button">UPGRADEN</button>
        </a>
      </CustomerJourneyFooter>
    </div>

    <Section1>
      <div className="container mt-5 pt-lg-0 pt-5">
        <h2>Onze werkwijze</h2>
      </div>

      <BackImage src={Back1} className="mt-5 mt-lg-0" />
    </Section1>

    <div className="py-5 py-lg-4" />
  </Layout>
)

const BackImage = styled(Image)`
  width: 100%;
`

const Section1 = styled.div`
  position: relative;
  margin-top: 100px;
  margin-bottom: 185px;

  h2 {
    font-size: ${(props) => props.theme.font.size.xxl};
    font-family: ${(props) => props.theme.font.family.main};
  }

  @media screen and (max-width: 768px) {
    margin-top: -20px;
    margin-bottom: 85px;

    h2 {
      font-size: ${(props) => props.theme.font.size.xl};
      font-family: ${(props) => props.theme.font.family.main};
    }
  }
`

const HeroContainer = styled.div`
  position: relative;
  margin-top: 0px;
`

const Hero = ({ fields, dots }) => (
  <HeroContainer className="d-flex flex-wrap">
    <div className="w-100 w-lg-50 mb-5 mb-lg-0 px-4 px-lg-0">
      <HeroContent>
        <Content content={fields.description} />

        <div className="px-5">
          <DotsContent content={dots} />
        </div>
      </HeroContent>
    </div>
    <div className="w-100 w-lg-50">
      <HeroImage
        src={fields.image}
        style={{ width: '100%', height: '100%', minHeight: 500 }}
      />
    </div>
  </HeroContainer>
)

const HeroImage = styled(ImageBackground)`
  width: 100%;

  @media (min-width: 992px) {
    min-height: 640px;
    height: 100%;
  }

  @media (max-width: 991px) {
    min-height: 0;
    padding-top: 50%;
    height: auto;
  }

  @media screen and (max-width: 456px) {
    min-height: 480px;
    height: 480px;
    max-height: 480px;
    background-position: 40% 50% !important;

    &:before {
      background-position: 40% 50% !important;
    }

    &:after {
      background-position: 60% 50% !important;
    }
  }
`

const HeroContent = styled(ContainerLeft)`
  color: ${(props) => props.theme.color.text.light};
  margin-top: 120px;

  @media screen and (max-width: 991px) {
    margin-top: 60px;
    margin-left: 0;
  }

  h1 {
    font-size: ${(props) => props.theme.font.size.xxl};
    font-family: ${(props) => props.theme.font.family.main};
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: ${(props) => props.theme.font.size.l};
    font-family: ${(props) => props.theme.font.family.secondary};
  }

  @media screen and (max-width: 456px) {
    margin-top: 40px;

    h1 {
      font-size: ${(props) => props.theme.font.size.xl};
    }

    p {
      font-size: ${(props) => props.theme.font.size.sm};
      line-height: ${(props) => props.theme.font.size.l};
    }
  }
`

const UBXImage = styled.div`
  width: 80%;
  margin-top: -40px;
  position: relative;
  background-size: contain !important;
  background-position-x: 0 !important;

  @media screen and (max-width: 768px) {
    margin-top: -20px;
    width: 100%;
  }

  & .gatsby-image-wrapper {
    max-height: 250px;
    overflow: initial !important;

    & img {
      max-height: 350px;
      object-fit: contain;
    }
  }
`

export default PageTemplate

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
        story {
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          dots
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`
